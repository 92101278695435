<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('surveys.surveys')"
                icon="mdi-file-document-edit-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        v-if="isInRole(0)"
                        :loading="loading"
                        @click="newSurvey"
                        color="primary darken-1"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-row class="pa-10" no-gutters justify="center">
                <v-col
                    v-for="survey in surveys"
                    :key="survey.surveyGuid"
                    cols="auto"
                >
                    <Survey-Card
                        :survey="survey"
                        :getSurveys="getSurveysList"
                    />
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import PageHeader from "../../components/PageHeader.vue";
import SurveyCard from "./SurveyCard.vue";
import SurveysApi from "../../Api/SurveysApi";

export default {
    components: { PageHeader, SurveyCard },
    data() {
        return {
            loading: true,
            surveys: []
        };
    },
    created() {
        if (!this.isInRole(0)) this.redirectUnauthorizedUsers();

        this.loading = true;
        this.getSurveysList();
    },
    methods: {
        newSurvey() {
            this.$router.push({
                name: "surveys.survey"
            });
        },
        getSurveysList() {
            try {
                this.loading = true;

                SurveysApi.getSurveys()
                    .then(response => {
                        this.surveys = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
