<template>
    <v-card
        class="ma-2"
        max-width="350"
        min-width="350"
        @dblclick="editItem(survey)"
        elevation="0"
        outlined
    >
        <v-system-bar
            height="35"
            class="pa-0"
            :color="survey.isActive ? '#66BB6A' : '#EF5350'"
            ><v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="white">mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        @click="editItem(survey)"
                        link
                        v-if="isInRole(0)"
                    >
                        <v-icon small>mdi-pencil-outline</v-icon>
                        <v-list-item-title
                            class="mx-1"
                            v-text="$t('edit')"
                        ></v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="changeActivation(survey)"
                        v-if="isInRole(0)"
                    >
                        <v-icon small>mdi-file-cog-outline</v-icon
                        ><v-list-item-title
                            class="mx-1"
                            v-text="$t('surveys.changeActivation')"
                        ></v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="deleteItem(survey)"
                        v-if="isInRole(0)"
                    >
                        <v-icon color="red" small>mdi-delete-outline</v-icon
                        ><v-list-item-title
                            class="mx-1"
                            v-text="$t('delete')"
                        ></v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <span class="white--text">{{
                survey.startDate | moment("yyyy-MM-DD hh:mm A")
            }}</span
            ><v-spacer></v-spacer>
            <span class="white--text">{{
                survey.endDate | moment("yyyy-MM-DD hh:mm A")
            }}</span>
            <v-spacer></v-spacer>
        </v-system-bar>
        <v-container fill-height fluid>
            <v-row>
                <v-col class="center"
                    ><h3>{{ survey.title }}</h3></v-col
                >
            </v-row>
        </v-container>
        <!-- Delete popup -->
        <confirm-dialog
            :openDialog="dialogDelete"
            :onClicked="deleteItemConfirm"
            :onClose="closeDelete"
            toolBarColor="red darken-2"
        ></confirm-dialog>
    </v-card>
</template>
<script>
import SurveysApi from "../../Api/SurveysApi";

import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
    props: ["survey", "getSurveys"],
    components: { ConfirmDialog },
    data() {
        return {
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {}
        };
    },
    created() {},
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.$router.push({
                name: "surveys.survey",
                params: { id: item.surveyGuid }
            });
        },
        deleteItem(item) {
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            try {
                SurveysApi.deleteSurvey(this.editedItem.surveyGuid)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.getSurveys();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.closeDelete();
                    });
            } catch (error) {
                console.log(error);
            }
        },
        changeActivation(survey) {
            try {
                SurveysApi.changeActivation(survey.surveyGuid)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.getSurveys();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedIndex = -1;
            });
        }
    }
};
</script>
<style scoped>
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    text-align: center;
}
</style>
